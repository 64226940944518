import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import uploadIcon from "../IMg/uploadIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import { IoIosCloseCircle } from "react-icons/io";
import Loader from "../../CompanyFolder/CompanyList/Loader";
import { Input } from "reactstrap";
import { heicTo } from 'heic-to';
import Webcam from 'react-webcam';

const PhotoUploadSection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pictureData, setPictureData] = useState([]);
  const [uploadedImages, setUploadedImages] = useState({});
  const [loading, setLoading] = useState(false);
  const [explanations, setExplanations] = useState({});
  const fileInputRefs = useRef([]);
  const [queryParams, setQueryParams] = useState({});
  const [hashParams, setHashParams] = useState('');
  const [requestData, setRequestData] = useState();
  const currentUser = (JSON.parse(localStorage.getItem("userData"))).designation;
  const [cameraStates, setCameraStates] = useState({});
  const [capturedImages, setCapturedImages] = useState({});
  const webcamRef = useRef(null);


  const handleOpenCamera = async (pictureId) => {
    try {
      // Check camera permission
      const cameraPermission = await navigator.permissions.query({ name: 'camera' });
      if (cameraPermission.state === 'denied') {
        alert('Camera permission is required. Please enable camera access and try again.');
        return;
      }
  
      // Check location permission
      const locationPermission = await navigator.permissions.query({ name: 'geolocation' });
      if (locationPermission.state === 'denied') {
        alert('Location permission is required. Please enable location services and try again.');
        return;
      }
  
      // Proceed with camera opening
      setCameraStates({});
      setCameraStates((prev) => ({ ...prev, [pictureId]: true }));
      setCapturedImages((prev) => ({ ...prev, [pictureId]: null }));
    } catch (error) {
      console.error('Error checking permissions:', error);
      alert('Unable to access camera or location services. Please check your device settings.');
    }
  };

  const handleCapture = (pictureId) => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setCapturedImages((prev) => ({ ...prev, [pictureId]: imageSrc }));
      setCameraStates((prev) => ({ ...prev, [pictureId]: false }));
    } else {
      console.error("Failed to capture image");
    }
  };


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    setQueryParams(paramsObject);
    const hash = location.hash;
    if (hash) {
      setHashParams(hash.substring(1));

    }
  }, [location]);

  const getRequestsList = async () => {
    const msiId = queryParams.requestId;

    if (Number(location.state?.requestId) || Number(msiId)) {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestsList`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId) },
        "GET"
      );

      if (isSuccess) {
        setRequestData(data?.data[0])
      }
    }
  };

  const getPictureFormQuestionList = async () => {
    setLoading(true);

    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getPictureFormQuestionList`,
        {},
        { type: "ALL" },
        "GET"
      );
      setLoading(false);

      if (isSuccess && Array.isArray(data.data)) {
        setPictureData(data.data);
      } else {
        console.error("API response is not an array:", data);
        setPictureData([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setPictureData([]);
    }
  };
  const convertHeicToImage = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const convertedBlob = await heicTo({ blob, type: 'image/jpeg' });
      const objectURL = URL.createObjectURL(convertedBlob);
      return objectURL;
    } catch (error) {
      console.error('Error converting HEIC file:', error);
      return null;
    }
  };

  const convertHeicFields = async (data) => {
    const convertedData = { ...data };

    for (let i = 1; i <= 19; i++) {
      const pField = `p${i}`;
      if (convertedData[pField] && (convertedData[pField].endsWith('.heic') || convertedData[pField].endsWith('.HEIC'))) {
        const convertedImageUrl = await convertHeicToImage(convertedData[pField]);
        if (convertedImageUrl) {
          convertedData[pField] = convertedImageUrl;
        }
      }
    }

    return convertedData;
  };

  const getRequestPictures = async () => {
    const msiId = queryParams.requestId;
    if (location.state?.requestId || msiId) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POST_LOGIN_API_URL}getRequestPictures`,
          {},
          { requestId: location.state?.requestId || msiId },
          "GET"
        );
        if (isSuccess && data.data.length) {
          const tmpData = data.data[0]
          const convertedData = await convertHeicFields(tmpData);
          setUploadedImages(convertedData);
          for (let i = 1; i <= 19; i++) {
            const key = `p${i}a`;
            if (tmpData[key] !== null) {
              explanations[key] = true;
              explanations[`${key}value`] = tmpData[key];
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }
  };

  const uploadPicture = async (pictureUrl, pictureId) => {
    const msiId = queryParams.requestId;
  
    // First check if we have location permission
    const checkLocationPermission = async () => {
      try {
        const result = await navigator.permissions.query({ name: 'geolocation' });
        return result.state === 'granted' || result.state === 'prompt';
      } catch (error) {
        console.error('Error checking location permission:', error);
        return false;
      }
    };
  
    // Function to handle the actual upload
    const handleUploadWithLocation = async (position) => {
      try {
        const { latitude, longitude, accuracy } = position.coords;
        console.log(`Location obtained - Lat: ${latitude}, Long: ${longitude}, Accuracy: ${accuracy}m`);
  
        // Construct location string
        const jioAddress = `${latitude} ${longitude}`;
  
        const { isSuccess, data } = await apiCall(
          `${config.POST_LOGIN_API_URL}uploadPicture`,
          {},
          {
            requestId: Number(location.state?.requestId) || Number(msiId),
            pictureId,
            pictureUrl,
            latitude,
            longitude,
            jioAddress,
          },
          "POST"
        );
  
        if (isSuccess) {
          const tmpData = data.data[0];
          const convertedData = await convertHeicFields(tmpData);
          setUploadedImages(convertedData);
          setCameraStates((prev) => ({ ...prev, [pictureId]: false }));
          setCapturedImages((prev) => ({ ...prev, [pictureId]: null }));
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('Error during upload:', error);
        alert('Failed to upload image. Please try again.');
      }
    };
  
    try {
      // Check if geolocation is supported
      if (!navigator.geolocation) {
        throw new Error('Geolocation is not supported by this browser');
      }
  
      // Check location permission
      const hasLocationPermission = await checkLocationPermission();
      if (!hasLocationPermission) {
        alert('Location permission is required to upload photos. Please enable location services and try again.');
        return;
      }
  
      // Show loading state  
      // Get location with retry logic
      const getLocationWithRetry = (retries = 3) => {
        return new Promise((resolve, reject) => {
          const attempt = (retriesLeft) => {
            navigator.geolocation.getCurrentPosition(
              (position) => resolve(position),
              (error) => {
                console.error(`Location attempt failed: ${error.message}`);
                if (retriesLeft > 0) {
                  console.log(`Retrying... ${retriesLeft} attempts left`);
                  attempt(retriesLeft - 1);
                } else {
                  reject(error);
                }
              },
              {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
              }
            );
          };
          attempt(retries);
        });
      };
  
      // Get location and upload
      const position = await getLocationWithRetry();
      await handleUploadWithLocation(position);
  
    } catch (error) {
      console.error('Error in upload process:', error);
      alert('Unable to get location. Please ensure location services are enabled and try again.');
    } finally {
      // File Uploaded Successfully: Need to add loader
    }
  };



  const completePictureform = async () => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}completePictureform`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId) },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const deletedPicture = async (pictureUrl, pictureId) => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}deletedPicture`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId), pictureId, pictureUrl, jioAddress: null },
        "POST"
      );
      if (isSuccess) {
        const tmpData = data.data[0]
        const convertedData = await convertHeicFields(tmpData);

        // Reset states related to the deleted picture
        setUploadedImages(convertedData);
        setCameraStates(prev => ({ ...prev, [pictureId]: false }));
        setCapturedImages(prev => ({ ...prev, [pictureId]: null }));

        // Reset the file input
        if (fileInputRefs.current) {
          const index = pictureData.findIndex(
            pic => pic.reportId.split(".")[0].toLowerCase() === pictureId
          );
          if (index !== -1 && fileInputRefs.current[index]) {
              fileInputRefs.current[index].value = ''; // Clear the file input
            }
          }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleFileChange = async (event, pictureId) => {
    try {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${event.target.files[0]?.name}`;
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("fileName", fileName);
      formData.append("filePath", "msi/");

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(
        `${config.AUTH_API_URL}uploadFile`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const result = await response.json();
      const url = result.url;
      uploadPicture(url, pictureId);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUploadClick = (e, pictureId) => {
    if (capturedImages[pictureId]) {
      // Convert the capturedImages[pictureId] (assuming it's a data URL or Blob) to a File object
      const file = dataURLToFile(capturedImages[pictureId], "captured-image.jpg"); // Adjust the name as needed

      // Create a synthetic event object to pass to handleFileChange
      const syntheticEvent = {
        target: {
          files: [file],  // Simulating the event target's files array
        },
      };

      // Call handleFileChange with the synthetic event and pictureId
      handleFileChange(syntheticEvent, pictureId);
    } else {
      console.error("No captured image to upload.");
    }
  };

  // Utility function to convert data URL to File object
  const dataURLToFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getPictureFormQuestionList();
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      if (location.state?.requestId || queryParams.requestId)
        getRequestPictures();
      getRequestsList();
    }
  }, [location.state?.requestId, queryParams]);

  const textStyle = {
    fontSize: "16px",
    color: "rgba(0, 80, 164, 1)",
  };

  const handleIconClick = (index) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    } else {
      console.error(`File input ref at index ${index} is not available.`);
    }
  };

  const handleCheckboxChange = (index) => {
    if (explanations && explanations[index])
      deletedPicture(String(null), index + 'value');

    const parts = index.split('a');
    if (uploadedImages[parts[0]]) {
      deletedPicture(String(null), parts[0]);
    }
    setExplanations((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const uploadPictureData = async (pictureUrl, pictureId) => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}uploadPicture`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId), pictureId, pictureUrl },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const [facingMode, setFacingMode] = useState("user");


  const handleFlipCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const handleExplanationChange = (index, value) => {
    setExplanations((prev) => ({
      ...prev,
      [index]: value,
    }));
    uploadPictureData(value, index)
  };


  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mt-4" style={textStyle}>

      {requestData?.status != '4' && <>
        <span className="d-flex justify-content-end">
          <button onClick={completePictureform} className="btn btn-success next-btn ps-4 pe-4 mb-3">
            Complete
          </button>
        </span>
      </>}

      {pictureData.map((picture, index) => (
        picture.reportId == 'P19' ? (
          currentUser == 'admin' ?
            <div className="mb-4" key={picture.id}>
              <h5 style={{ fontSize: "14px" }}>
                {picture.reportId}: {picture.title}
              </h5>
              <div className="p-2" style={{ border: "1px solid #0050A4" }}>
                <div
                  style={{ borderBottom: "1px solid rgba(0, 80, 164, 1)" }}
                  onClick={() => handleIconClick(index)}
                >
                  <i
                    style={{
                      backgroundColor: "#0050A4",
                      color: "white",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    className="bi bi-cloud-upload"
                  ></i>
                  <span style={{ marginLeft: "20em", cursor: "pointer" }}>
                    Click here to upload the image
                  </span>
                </div>
                <div className="d-flex">
                  <div className="position-relative m-2 mt-4">
                    <div style={{ position: "relative", display: "inline-block" }}>
                      {uploadedImages[picture.reportId.split(".")[0].toLowerCase()] ? (
                        <>
                          <img
                            src={uploadedImages[picture.reportId.split(".")[0].toLowerCase()]}
                            alt="Uploaded"
                            className="img-fluid"
                            style={{
                              background: "#EDF4FC",
                              padding: "20px",
                              height: "30vh",
                              width: "20vw",
                            }}
                          />
                          <IoIosCloseCircle
                            onClick={() =>
                              deletedPicture(
                                picture.reportId.split(".")[0].toLowerCase(),
                                picture.reportId.split(".")[0].toLowerCase()
                              )
                            }
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                              fontSize: "24px",
                              color: "black",
                              cursor: "pointer",
                            }}
                          />
                          <div>
                            Uploaded Time : {(() => {
                              try {
                                // First attempt: Try to parse without reformatting
                                const date = new Date(uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']);
                                if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                                return date.toLocaleString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",

                                });
                              } catch {
                                // Fallback: Reformat date to MM-DD-YYYY format and parse again
                                const reformattedDate = new Date(
                                  uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']
                                    .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                );
                                return reformattedDate.toLocaleString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",

                                });
                              }
                            })()}
                          </div>




                        </>
                      ) : (
                        <img
                          src={uploadIcon}
                          alt="placeholder"
                          className="img-fluid"
                          style={{
                            background: "#EDF4FC",
                            padding: "20px",
                            height: "30vh",
                            width: "20vw",
                          }}
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={(ref) => (fileInputRefs.current[index] = ref)}
                      onChange={(e) =>
                        handleFileChange(e, picture.reportId.split(".")[0].toLowerCase())
                      }
                      style={{ display: "none" }}
                    />
                    {picture.reportId === 'P19' ? (
                      <div className="mt-3">
                        <div className="input-group" id="inputGroup_P19">
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${requestData?.siteAddress},+${requestData?.siteCity},+${requestData?.siteState}+${requestData?.siteZipcode}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Location on Google Maps
                          </a>
                        </div>
                        <label
                          htmlFor={`unable-${index}`}
                          className="ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          Remarks:
                        </label>
                        <Input
                          className="mt-2"
                          placeholder="Please explain location..."
                          value={explanations['p19avalue'] || ""}
                          onChange={(e) => {
                            uploadPictureData(e.target.value, 'p19avalue');
                            setExplanations((prev) => ({
                              ...prev,
                              p19avalue: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    ) : (
                      <div className="mt-3">
                        <input
                          type="checkbox"
                          id={`unable-${index}`}
                          style={{ width: "20px" }}
                          checked={!!explanations[picture.reportId.split(".")[0].toLowerCase() + "a"]}
                          onChange={() => handleCheckboxChange(picture.reportId.split(".")[0].toLowerCase() + "a")}
                        />
                        <label
                          htmlFor={`unable-${index}`}
                          className="ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          Unable to take photo, please explain
                        </label>
                        {explanations[picture.reportId.split(".")[0].toLowerCase() + "a"] && (
                          <>
                            <Input
                              className="mt-2"
                              placeholder="Please explain why you were unable to take a photo..."
                              value={explanations[picture.reportId.split(".")[0].toLowerCase() + "a" + "value"] || ""}
                              onChange={(e) => handleExplanationChange(picture.reportId.split(".")[0].toLowerCase() + "a" + "value", e.target.value)}
                            />
                            <div>
                              Uploaded Time : {(() => {
                                try {
                                  // First attempt: Try to parse without reformatting
                                  const date = new Date(uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']);
                                  if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                                  return date.toLocaleString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",

                                  });
                                } catch {
                                  // Fallback: Reformat date to MM-DD-YYYY format and parse again
                                  const reformattedDate = new Date(
                                    uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']
                                      .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                  );
                                  return reformattedDate.toLocaleString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",

                                  });
                                }
                              })()}
                            </div>


                          </>

                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> : <></>
        ) : (
          <div className="mb-4" key={picture.id}>
            <h5 style={{ fontSize: "14px" }}>
              {picture.reportId}: {picture.title}
            </h5>
            <div className="p-2" style={{ border: "1px solid #0050A4" }}>
              <div>
                <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
                  <div >
                    {!cameraStates[picture.reportId.split(".")[0].toLowerCase()] &&
                      !capturedImages[picture.reportId.split(".")[0].toLowerCase()] && (
                        <button
                          onClick={() => handleOpenCamera(picture.reportId.split(".")[0].toLowerCase())}
                          style={{
                            backgroundColor: "#0050A4",
                            color: "white",
                            padding: "10px 15px",
                            border: "none",
                            cursor: "pointer",
                            marginBottom: "20px",
                          }}
                        >
                          Open Camera To Take Picture
                        </button>
                      )}

                    <div
                      style={{ borderBottom: "1px solid rgba(0, 80, 164, 1)" }}
                      onClick={() => handleIconClick(index)}
                    >
                      <i
                        style={{
                          backgroundColor: "#0050A4",
                          color: "white",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                        className="bi bi-cloud-upload"
                      ></i>
                      <span
                        style={{
                          marginLeft: "10px", // Adjusted margin for proper alignment
                          cursor: "pointer",
                        }}
                      >
                        Click here to upload the image
                      </span>
                    </div>
                  </div>


                  {cameraStates[picture.reportId.split(".")[0].toLowerCase()] && (
                    <div>
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                          width: 1280,
                          height: 720,
                          facingMode: facingMode,

                        }}
                        style={{ width: "100%", height: "auto" }}
                      />
                      <button
                        onClick={() => handleCapture(picture.reportId.split(".")[0].toLowerCase())}
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#0050A4",
                          color: "white",
                          padding: "10px 15px",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        Capture Photo
                      </button>
                      <button
                        onClick={handleFlipCamera}
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                          backgroundColor: "#FFA500",
                          color: "white",
                          padding: "10px 15px",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        Flip Camera
                      </button>
                    </div>
                  )}

                  {capturedImages[picture.reportId.split(".")[0].toLowerCase()] && (
                    <div style={{ marginTop: "20px" }}>
                      <h3>Captured Image:</h3>
                      <img
                        src={capturedImages[picture.reportId.split(".")[0].toLowerCase()]}
                        alt="Captured"
                        style={{
                          width: "300px",
                          height: "auto",
                          marginBottom: "20px",
                          display: "block",
                        }}
                      />
                      <button
                        style={{
                          backgroundColor: "#0050A4",
                          color: "white",
                          padding: "10px 15px",
                          border: "none",
                          cursor: "pointer",
                          marginBottom: "10px",
                        }}
                        onClick={() => handleOpenCamera(picture.reportId.split(".")[0].toLowerCase())}
                      >
                        Retake Photo
                      </button>
                    </div>
                  )}

                  {capturedImages[picture.reportId.split(".")[0].toLowerCase()] && <div style={{ marginTop: "20px" }}>

                    <button
                      style={{
                        backgroundColor: "#0050A4",
                        color: "white",
                        padding: "10px 15px",
                        border: "none",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                      onClick={(e) => handleUploadClick(e, picture.reportId.split(".")[0].toLowerCase())} // Upload captured image when button is clicked

                    >
                      Upload Photo
                    </button>
                  </div>}
                </div>

              </div>
              <div className="d-flex">
                <div className="position-relative m-2 mt-4">
                  <div style={{ position: "relative", display: "inline-block" }}>
                    {uploadedImages[picture.reportId.split(".")[0].toLowerCase()] ? (
                      <>
                        <img
                          src={uploadedImages[picture.reportId.split(".")[0].toLowerCase()]}
                          alt="Uploaded"
                          className="img-fluid"
                          style={{
                            background: "#EDF4FC",
                            padding: "20px",
                            height: "30vh",
                            width: "20vw",
                          }}
                        />
                        <IoIosCloseCircle
                          onClick={() =>
                            deletedPicture(
                              picture.reportId.split(".")[0].toLowerCase(),
                              picture.reportId.split(".")[0].toLowerCase()
                            )
                          }
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            fontSize: "24px",
                            color: "black",
                            cursor: "pointer",
                          }}
                        />
                        <div>
                          Uploaded Location : {uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'Location']}
                        </div>
                        <div>
                          Uploaded Time : {(() => {
                            try {
                              // First attempt: Try to parse without reformatting
                              const date = new Date(uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']);
                              if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                              return date.toLocaleString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",

                              });
                            } catch {
                              // Fallback: Reformat date to MM-DD-YYYY format and parse again
                              const reformattedDate = new Date(
                                uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']
                                  .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                              );
                              return reformattedDate.toLocaleString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",

                              });
                            }
                          })()}
                        </div>



                      </>
                    ) : (
                      <img
                        src={uploadIcon}
                        alt="placeholder"
                        className="img-fluid"
                        style={{
                          background: "#EDF4FC",
                          padding: "20px",
                          height: "30vh",
                          width: "20vw",
                        }}
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={(ref) => (fileInputRefs.current[index] = ref)}
                    onChange={(e) =>
                      handleFileChange(e, picture.reportId.split(".")[0].toLowerCase())
                    }
                    style={{ display: "none" }}
                  />
                  {picture.reportId === 'P19' ? (
                    <div className="mt-3">
                      <div className="input-group" id="inputGroup_P19">
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${location.state?.requestData?.siteAddress},+${location.state?.requestData?.siteCity},+${location.state?.requestData?.siteState}+${location.state?.requestData?.siteZipcode}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Location on Google Maps
                        </a>
                      </div>
                      <label
                        htmlFor={`unable-${index}`}
                        className="ms-2"
                        style={{ fontSize: "14px" }}
                      >
                        Remarks:
                      </label>
                      <Input
                        className="mt-2"
                        placeholder="Please explain location..."
                        value={explanations['p19avalue'] || ""}
                        onChange={(e) => {
                          uploadPictureData(e.target.value, 'p19avalue');
                          setExplanations((prev) => ({
                            ...prev,
                            p19avalue: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  ) : (
                    <div className="mt-3">
                      <input
                        type="checkbox"
                        id={`unable-${index}`}
                        style={{ width: "20px" }}
                        checked={!!explanations[picture.reportId.split(".")[0].toLowerCase() + "a"]}
                        onChange={() => handleCheckboxChange(picture.reportId.split(".")[0].toLowerCase() + "a")}
                      />
                      <label
                        htmlFor={`unable-${index}`}
                        className="ms-2"
                        style={{ fontSize: "14px" }}
                      >
                        Unable to take photo, please explain
                      </label>
                      {explanations[picture.reportId.split(".")[0].toLowerCase() + "a"] && (
                        <>
                          <Input
                            className="mt-2"
                            placeholder="Please explain why you were unable to take a photo..."
                            value={explanations[picture.reportId.split(".")[0].toLowerCase() + "a" + "value"] || ""}
                            onChange={(e) => handleExplanationChange(picture.reportId.split(".")[0].toLowerCase() + "a" + "value", e.target.value)}
                          />
                          <div>
                            Uploaded Time : {(() => {
                              try {
                                // First attempt: Try to parse without reformatting
                                const date = new Date(uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']);
                                if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                                return date.toLocaleString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",

                                });
                              } catch {
                                // Fallback: Reformat date to MM-DD-YYYY format and parse again
                                const reformattedDate = new Date(
                                  uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']
                                    .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                );
                                return reformattedDate.toLocaleString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",

                                });
                              }
                            })()}
                          </div>

                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ))}
    </div>

  );
};

export default PhotoUploadSection;
