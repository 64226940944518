import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import uploadIcon from "../TabForm/IMg/uploadIcon.png";
import { apiCall } from '../../services/ApiCall';
import config from "../../config/config.json";
import { useLocation } from 'react-router-dom';
import { Col, Row, Table } from 'react-bootstrap';
import './style.css';
import ImageViewer from '../ImageViewer/ImageViewer';
import { heicTo } from 'heic-to';
import CircularProgress from '@mui/material/CircularProgress';

const PDF = ({ module, answer, handleChange, index }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [uploadedImages, setUploadedImages] = useState({});
  const [explanations, setExplanations] = useState({});
  const [requestData, setRequestData] = useState({});


  const getRequestsList = async () => {
    if (Number(location.state?.requestId)) {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestsList`,
        {},
        { requestId: Number(location.state?.requestId) },
        "GET"
      );

      if (isSuccess) {
        setRequestData(data?.data[0])
      }
    }
  };
  const convertHeicToImage = async (url) => {
    try {
      const response = await fetch(url); 
      const blob = await response.blob();
      const convertedBlob = await heicTo({
        blob,
        type: "image/jpeg"
      });
      const objectURL = URL.createObjectURL(convertedBlob); 
      return objectURL;
    } catch (error) {
      console.error('Error converting HEIC file:', error);
      return null; 
    }
  };
  
  const convertHeicFields = async (data) => {
    const convertedData = { ...data };
  
    for (let i = 1; i <= 19; i++) {
      const pField = `p${i}`;
      if (convertedData[pField] && (convertedData[pField].endsWith('.heic') || convertedData[pField].endsWith('.HEIC'))) {
        const convertedImageUrl = await convertHeicToImage(convertedData[pField]);
        if (convertedImageUrl) {
          convertedData[pField] = convertedImageUrl;
        }
      }
    }
  
    return convertedData; 
  };
  
  const getRequestPictures = async () => {
    if (location.state?.requestId) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POST_LOGIN_API_URL}getRequestPictures`,
          {},
          { requestId: location.state?.requestId },
          "GET"
        );
        if (isSuccess && data.data.length) {
          const tmpData = data.data[0]
          const convertedData = await convertHeicFields(tmpData);
          setUploadedImages(convertedData);
          for (let i = 1; i <= 19; i++) {
            const key = `p${i}a`;
            if (tmpData[key] !== null) {
              explanations[key] = true;
              explanations[`${key}value`] = tmpData[key];
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }
  };

  useEffect(() => {
    getRequestPictures();
    getRequestsList();

  }, [location.state?.requestId]);
  const getAnswerByKey = (answerKey) => {
    const result = answer && answer.find((item) => item.answerKey == answerKey);
    if (result && answerKey === 'D55. CA-4F') {

      if (typeof result.answer === 'string') {
        result.answer = result.answer.split(", ");
      }

      if (result.answer.includes('Shredder onsite. Provide name and address and phone') &&
        !result.answer.includes('3rd party shredder onsite: Name and Address and Phone Number of the company')) {
        result.answer.push('3rd party shredder onsite: Name and Address and Phone Number of the company');
      }

      if (result.answer.includes('Shredder offsite. Provide the company name and address and phone number of the service provider:') &&
        !result.answer.includes('3rd party Shredder offsite: Name and Address and Phone Number of the company')) {
        result.answer.push('3rd party Shredder offsite: Name and Address and Phone Number of the company');
      }

      if (result.answer.includes('Own Shredder. Provide the name and address and phone number of the service provider:') &&
        !result.answer.includes('Company Owned Shredder :')) {
        result.answer.push('Company Owned Shredder :');
      }

      if (result.answer.includes('Document destruction service. Provide the name and address and phone number of the service provider:') &&
        !result.answer.includes('Document destruction service.')) {
        result.answer.push('Document destruction service.');
      }

      if (result.answer.includes('N/A. They do not destroy confidential paper documents.') &&
        !result.answer.includes('We do not destroy confidential paper documents at this location.')) {
        result.answer.push('We do not destroy confidential paper documents at this location.');
      }

      result.answer = result.answer.join(",");

    }
    return result ? result.answer : '';
  };

  const styles = {
    formHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#e7f3ff',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '10px'
    },
    h2: {
      margin: 0,
      fontSize: '24px',
      color: 'black',
      fontWeight: '600',
    },
    toggleButton: {
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '8px',
      fontSize: '18px',
      cursor: 'pointer',
    },
    formContent: {
      padding: '35px',
      borderRadius: '10px',
      border: 'none',
      paddingTop: "10px"

    },
    formGroup: {
      marginBottom: '20px'
    },
    label: {
      display: 'block',
      fontWeight: 'bold',
      marginBottom: '8px',
      fontSize: '16px'
    },
    inputRadioCheckbox: {
      width: '5%',
      marginRight: '8px',
    },
  };

  return (
    <div style={{ marginBottom: "50px", }}>

      {index === 0 ? (
        <div style={{ margin: '20px', border: "1px solid black", padding: "3px" }}>
          <div style={{ width: "100%", background: "lightgrey", display: "flex", justifyContent: "center", marginBottom: "10px", alignItems: "center", height: "6vh" }}>
            <div style={{ fontSize: "20px", fontWeight: 600 }}>Inspection ID: {requestData?.id}</div>
          </div>
          <Table bordered>
            <tbody>
              <tr>
                <td style={{ width: '20%' }}><strong>COMPANY NAME:</strong></td>
                <td>{requestData.companyName}</td>
                <td style={{ width: '20%' }}><strong>CONTACT NAME:</strong></td>
                <td>{requestData.contactName}</td>
              </tr>
              <tr>
                <td style={{ width: '20%' }}><strong>CONTACT PHONE:</strong></td>
                <td>{requestData.contactPhone !== 'undefined' ? requestData.contactPhone : 'N/A'}</td>
                <td style={{ width: '20%' }}><strong>CONTACT EMAIL:</strong></td>
                <td>{requestData.contactEmail}</td>
              </tr>
              <tr>
                <td style={{ width: '20%' }}><strong>INSPECTION DUE DATE:</strong></td>
                <td>
                  {requestData.scheduledDate
                    ? new Date(requestData.scheduledDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
                    : 'N/A'}
                </td>
                <td style={{ width: '20%' }}><strong>APPROVED DATE:</strong></td>
                <td>
                  {requestData.approvedDate
                    ? new Date(requestData.approvedDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td style={{ width: '20%' }}><strong>REFERENCE:</strong></td>
                <td>{requestData.araId}</td>
                <td style={{ width: '20%' }}><strong>INSPECTOR:</strong></td>
                <td>Cheri Kraunelis</td>
              </tr>
              <tr>
                <td style={{ width: '20%' }}><strong>ADDRESS:</strong></td>
                <td>{`${requestData.siteAddress}, ${requestData.siteCity}, ${requestData.siteState} ${requestData.siteZipcode}`}</td>
                <td style={{ width: '20%' }}><strong>NEXT RENEWAL DATE:</strong></td>
                <td>
                  {requestData.inspectionDate
                    ? new Date(requestData.inspectionDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td style={{ width: '20%' }}><strong>SITE ADMIN CONTACT:</strong></td>
                <td>{requestData.siteAdminName}</td>
                <td style={{ width: '20%' }}><strong>SITE MANAGER CONTACT:</strong></td>
                <td>{requestData.siteManagerName}</td>
              </tr>
              <tr>
                <td style={{ width: '20%' }}><strong>ADMIN EMAIL:</strong></td>
                <td>{requestData.siteAdminEmail}</td>
                <td style={{ width: '20%' }}><strong>MANAGER EMAIL:</strong></td>
                <td>{requestData.siteManagerEmail}</td>
              </tr>
              <tr>
                <td style={{ width: '20%' }}><strong>ADMIN PHONE:</strong></td>
                <td>{requestData.siteAdminPhone !== 'undefined' ? requestData.siteAdminPhone : 'N/A'}</td>
                <td style={{ width: '20%' }}><strong>MANAGER PHONE:</strong></td>
                <td>{requestData.siteManagerPhone !== 'undefined' ? requestData.siteManagerPhone : 'N/A'}</td>
              </tr>
            </tbody>

          </Table>
        </div>
      ) : null}

      <div style={{ marginBottom: "10px", marginLeft: "1%" }}>
        <h2 style={styles.h2}>{module.moduleTitle}</h2>
      </div>

      <div style={styles.formContent}>
        {module && module.questions.map((question) => (
          <Row className="g-5" style={{ marginBottom: '10px', display: "flex", justifyContent: "space-between" }}>
            <Col md={6} style={{ border: "1px solid black" }}>
              <Label style={{ color: "black", fontSize: "15px", fontWeight: 600 }}>
                {`${question?.reportId}: ${question?.questionTitle || question?.title}`}
              </Label>
            </Col>

            <Col md={6} style={{ border: "1px solid black" }}>
              {
                question?.reportId && (question?.reportId.includes('A-PHOTO') || question?.reportId.includes('P18') || question?.reportId.includes('P19')) ? (
                  (!uploadedImages[question.reportId.split(".")[0].toLowerCase()] && (explanations[question.reportId.split(".")[0].toLowerCase() + "a"] ?? '') !== '') ? (
                    <>
                      {question?.reportId == 'P19' && <div >
                        <div>
                          {uploadedImages[question.reportId.split(".")[0].toLowerCase()] ? (
                        <ImageViewer
                            image={uploadedImages[question.reportId.split(".")[0].toLowerCase()] || uploadIcon}
                            alt="placeholder"
                            className="img-fluid"
                            reportId={question.reportId.split(".")[0].toLowerCase()}
                            previewStyle={{
                              background: "none",
                              padding: "20px",
                              height: "30vh",
                              width: "20vw",
                            }}
                          />
                          ) : (
                            uploadedImages[question.reportId.split(".")[0].toLowerCase()] !== null ? (
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", width: "20vw" }}>
                                <CircularProgress />
                              </div>
                            ) : <ImageViewer
                              image={uploadIcon}
                              alt="placeholder"
                              className="img-fluid"
                              reportId={question.reportId.split(".")[0].toLowerCase()}
                              previewStyle={{
                                background: "none",
                                padding: "20px",
                                height: "30vh",
                                width: "20vw",
                              }}
                            />
                          )}
                        </div>
                        <div>
                          Uploaded Time: {(() => {
                            try {
                              // Extract the date string, and check if it exists
                              const dateString = uploadedImages[question.reportId.split(".")[0].toLowerCase() + 'time'];
                              if (!dateString) throw new Error("Date string not found");

                              // First attempt: Try to parse without reformatting
                              let date = new Date(dateString);
                              if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                              // Define the cutoff date of October 25, 2024
                              const cutoffDate = new Date("2024-10-22");

                              // Apply the condition based on the cutoff date
                              const formatOptions = date < cutoffDate
                                ? { day: "2-digit", month: "2-digit", year: "numeric" } // DD-MM-YYYY
                                : { month: "2-digit", day: "2-digit", year: "numeric" }; // MM-DD-YYYY
                              const locale = date < cutoffDate ? "en-GB" : "en-US"; // Use "en-US" for MM-DD-YYYY format

                              return date.toLocaleDateString(locale, formatOptions);
                            } catch {
                              // Fallback: Reformat date if needed, ensuring the date string exists
                              const dateString = uploadedImages[question.reportId.split(".")[0].toLowerCase() + 'time'];
                              if (dateString) {
                                // Reformat date to MM-DD-YYYY format if it is invalid
                                const reformattedDate = new Date(
                                  dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                );

                                // Define the cutoff date of October 25, 2024
                                const cutoffDate = new Date("2024-10-22");

                                // Apply the condition based on the cutoff date for the reformatted date
                                const formatOptions = reformattedDate < cutoffDate
                                  ? { day: "2-digit", month: "2-digit", year: "numeric" } // DD-MM-YYYY
                                  : { month: "2-digit", day: "2-digit", year: "numeric" }; // MM-DD-YYYY

                                const locale = reformattedDate < cutoffDate ? "en-US" : "en-US"; // Use "en-US" for MM-DD-YYYY format

                                return reformattedDate.toLocaleDateString(locale, formatOptions);
                              }
                              return "N/A"; // Return "N/A" if dateString is undefined
                            }
                          })()}



                        </div>
                        <div>
                        <label
                          htmlFor={`unable-${index}`}
                          className="ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          Remarks:
                        </label>
                        <Input
                          className="mt-2"
                          placeholder="Please explain location..."
                          value={explanations['p19avalue'] || ""}
                         
                        />
                        </div>
                      </div>}
                      <div className="mt-3">
                        <label className="ms-2" style={{ fontSize: "14px", color: "black" }}>
                          Unable to take photo, please explain
                        </label>
                        {explanations[question.reportId.split(".")[0].toLowerCase() + "a"] && (
                          <>
                            <Input
                              className="mt-2"
                              style={{ border: "none" }}
                              placeholder="Please explain why you were unable to take a photo..."
                              value={explanations[question.reportId.split(".")[0].toLowerCase() + "a" + "value"] || ""}
                            />


                            Uploaded Time: {(() => {
                              try {
                                // Extract the date string, and check if it exists
                                const dateString = uploadedImages[question.reportId.split(".")[0].toLowerCase() + 'time'];
                                if (!dateString) throw new Error("Date string not found");

                                // First attempt: Try to parse without reformatting
                                let date = new Date(dateString);
                                if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                                // Define the cutoff date of October 25, 2024
                                const cutoffDate = new Date("2024-10-22");

                                // Apply the condition based on the cutoff date
                                const formatOptions = date < cutoffDate
                                  ? { day: "2-digit", month: "2-digit", year: "numeric" } // DD-MM-YYYY
                                  : { month: "2-digit", day: "2-digit", year: "numeric" }; // MM-DD-YYYY
                                const locale = date < cutoffDate ? "en-GB" : "en-US";
                                return date.toLocaleDateString(locale, formatOptions);
                              } catch {
                                // Fallback: Reformat date if needed, ensuring the date string exists
                                const dateString = uploadedImages[question.reportId.split(".")[0].toLowerCase() + 'time'];
                                if (dateString) {
                                  // Reformat date to MM-DD-YYYY format if it is invalid
                                  const reformattedDate = new Date(
                                    dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                  );

                                  // Define the cutoff date of October 25, 2024
                                  const cutoffDate = new Date("2024-10-22");

                                  // Apply the condition based on the cutoff date for the reformatted date
                                  const formatOptions = reformattedDate < cutoffDate
                                    ? { day: "2-digit", month: "2-digit", year: "numeric" } // DD-MM-YYYY
                                    : { month: "2-digit", day: "2-digit", year: "numeric" }; // MM-DD-YYYY
                                  const locale = reformattedDate < cutoffDate ? "en-US" : "en-US";
                                  return reformattedDate.toLocaleDateString(locale, formatOptions);
                                }
                                return "N/A"; // Return "N/A" if dateString is undefined
                              }
                            })()}



                          </>
                        )}
                      </div>
                    </>

                  ) : (
                    <>
                      <div >
                        <div>
                          {uploadedImages[question.reportId.split(".")[0].toLowerCase()] ? (
                            <ImageViewer
                            image={uploadedImages[question.reportId.split(".")[0].toLowerCase()] || uploadIcon}
                            alt="placeholder"
                            className="img-fluid"
                            reportId={question.reportId.split(".")[0].toLowerCase()}
                            previewStyle={{
                              background: "none",
                              padding: "20px",
                              height: "30vh",
                              width: "20vw",
                            }}
                          />
                          ) : (
                            uploadedImages[question.reportId.split(".")[0].toLowerCase()] !== null ? (
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", width: "20vw" }}>
                                <CircularProgress />
                              </div>
                            ) : <ImageViewer
                              image={uploadIcon}
                              alt="placeholder"
                              className="img-fluid"
                              reportId={question.reportId.split(".")[0].toLowerCase()}
                              previewStyle={{
                                background: "none",
                                padding: "20px",
                                height: "30vh",
                                width: "20vw",
                              }}
                            />
                          )}
                        </div>
                        <div>
                          Uploaded Location : {uploadedImages[question.reportId.split(".")[0].toLowerCase() + 'Location']}
                        </div>
                        <div>
                          Uploaded Time: {(() => {
                            try {
                              // Extract the date string, and check if it exists
                              const dateString = uploadedImages[question.reportId.split(".")[0].toLowerCase() + 'time'];
                              if (!dateString) throw new Error("Date string not found");

                              // First attempt: Try to parse without reformatting
                              let date = new Date(dateString);
                              if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                              // Define the cutoff date of October 25, 2024
                              const cutoffDate = new Date("2024-10-22");

                              // Apply the condition based on the cutoff date
                              const formatOptions = date < cutoffDate
                                ? { day: "2-digit", month: "2-digit", year: "numeric" } // DD-MM-YYYY
                                : { month: "2-digit", day: "2-digit", year: "numeric" }; // MM-DD-YYYY
                              const locale = date < cutoffDate ? "en-GB" : "en-US"; // Use "en-US" for MM-DD-YYYY format
                              return date.toLocaleDateString(locale, formatOptions);

                            } catch {
                              // Fallback: Reformat date if needed, ensuring the date string exists
                              const dateString = uploadedImages[question.reportId.split(".")[0].toLowerCase() + 'time'];
                              if (dateString) {
                                // Reformat date to MM-DD-YYYY format if it is invalid
                                const reformattedDate = new Date(
                                  dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                );

                                // Define the cutoff date of October 25, 2024
                                const cutoffDate = new Date("2024-10-22");

                                // Apply the condition based on the cutoff date for the reformatted date
                                const formatOptions = reformattedDate < cutoffDate
                                  ? { day: "2-digit", month: "2-digit", year: "numeric" } // DD-MM-YYYY
                                  : { month: "2-digit", day: "2-digit", year: "numeric" }; // MM-DD-YYYY

                                const locale = reformattedDate < cutoffDate ? "en-US" : "en-US"; // Use "en-US" for MM-DD-YYYY format

                                return reformattedDate.toLocaleDateString(locale, formatOptions);
                              }
                              return "N/A"; // Return "N/A" if dateString is undefined
                            }
                          })()}



                        </div>
                        {question?.reportId === 'P19' && <div>
                        <label
                          htmlFor={`unable-${index}`}
                          className="ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          Remarks:
                        </label>
                        <Input
                          className="mt-2"
                          placeholder="Please explain location..."
                          value={(explanations['p19avalue'] || "").replace(/^\d{2}-\d{2}-\d{2}\s*/, "")}                         
                        />
                        </div>}
                      </div>
                    </>
                  )
                ) : null
              }
              {module.moduleId === 1 ? (
                <>
                  <div>Company Name : {requestData?.companyName}</div>
                  <div>Site Address : {`${requestData?.siteAddress} ${requestData?.siteCity} ${requestData?.siteState}`}</div>
                </>
              ) : question.subQuestions && question.subQuestions.length == 0 ? (

                <>
                  <div
                    style={{
                      border: "none",
                      color: "black",
                      width: "500px",
                      height: 'auto',
                      display: 'flex',
                      flexWrap: "wrap"
                    }}
                    className="mt-2"
                    type="text"
                    name={`subQuestion-${question.id}`}
                  >
                    {getAnswerByKey(question?.reportId)}

                  </div>

                </>
              ) : (
                question.subQuestions && question.subQuestions.map((subQuestion) => (
                  <div key={subQuestion.id}>
                    <FormGroup>
                      {subQuestion.optionType === "text" && (
                        <>
                          <Label>
                            <div style={{ color: "black", fontSize: "20px" }} className="mt-4">
                              {subQuestion?.option}
                            </div>
                            <div
                              style={{
                                border: "none",
                                color: "black",
                                width: "500px",
                                height: 'auto',
                                display: 'flex',
                                flexWrap: "wrap"
                              }}
                              className="mt-2"
                              type="text"
                              name={`subQuestion-${subQuestion.id}`}
                            >
                              {getAnswerByKey(question?.reportId)}

                            </div>
                          </Label>
                        </>
                      )}

                      {subQuestion.optionType === "checkbox" && (
                        <>
                          <Label style={{ display: "flex", marginTop: "10px" }}>
                            <Input
                              type="checkbox"
                              name={`subQuestion-${subQuestion.id}`}
                              style={{ background: "white", borderColor: "black" }}
                              disabled
                              checked={getAnswerByKey(question.reportId)?.split(",").includes(subQuestion.option.trim())}
                            />
                            <span style={{ fontSize: "13px", color: "black", marginLeft: "10px" }}>
                              {subQuestion.option}
                            </span>
                          </Label>
                          {subQuestion.details && subQuestion.details.map((detail) => (
                            <FormGroup key={detail.id}>
                              {getAnswerByKey(question.reportId)?.split(",").includes(subQuestion.option.trim()) && (
                                <>
                                  <Label>
                                    <div style={{ color: "black", fontSize: "14px" }} className="ms-4">
                                      {detail.option}
                                    </div>
                                    <div
                                      style={{
                                        border: "none",
                                        color: "black",
                                        width: "500px",
                                        height: 'auto',
                                        display: 'flex',
                                        height: 'auto',
                                        flexWrap: "wrap"
                                      }}
                                      className="mt-2 ms-4"
                                      type="text"
                                      name={`subQuestion-${detail.id}`}

                                    >
                                      {getAnswerByKey(detail.answerKey)}
                                    </div>

                                  </Label>
                                </>
                              )}
                            </FormGroup>
                          ))}
                        </>
                      )}

                      {subQuestion.optionType === "radio" && (
                        <>
                          <Label style={{ display: "flex", marginTop: "10px" }}>
                            <Input
                              type="radio"
                              name={`subQuestion-${subQuestion.id}`}
                              value={subQuestion.option}
                              checked={getAnswerByKey(question.reportId) === subQuestion.option}
                              disabled
                            />
                            <span style={{ fontSize: "13px", color: "black", marginLeft: "10px" }}>
                              {subQuestion.option}
                            </span>
                          </Label>
                          {subQuestion.details && subQuestion.details.map((detail) => (
                            <FormGroup key={detail.id}>
                              {getAnswerByKey(question.reportId) === subQuestion.option && (
                                <>
                                  <Label>
                                    <div style={{ color: "black", fontSize: "14px" }} className="ms-4">
                                      {detail.option}
                                    </div>
                                    <div
                                      style={{
                                        border: "none",
                                        color: "black",
                                        width: "500px",
                                        height: 'auto',
                                        display: 'flex',
                                        height: 'auto',
                                        flexWrap: "wrap"
                                      }}
                                      className="mt-2 ms-4"
                                      type="text"
                                      name={`subQuestion-${detail.id}`}

                                    >
                                      {getAnswerByKey(detail.answerKey)}
                                    </div>
                                  </Label>
                                </>
                              )}
                            </FormGroup>
                          ))}
                        </>
                      )}
                    </FormGroup>
                  </div>
                ))
              )}
            </Col>




          </Row>
        ))}
      </div>
    </div>
  );
};

export default PDF;

